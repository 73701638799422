<template>
	<div class="index">
		<div class="main-box">
			<div class="title">
				<div class="_pc-font-32 _b">{{title}}</div>
				<div class="iconfont2 icon-cha1 close" @click="close"></div>
			</div>
			<!-- 修改密码 -->
			<div class="_w-max edit-info">
				<div class="li-box">
					<!-- <div class="item">
						<div class="_pc-font-20 _pc-mar-bottom-20">{{ $t('用户昵称') }}</div>
						<input type="text" :placeholder="$t('请输入')" v-model="nickname">
					</div> -->
					<div class="item">
						<div class="_pc-font-20 _pc-mar-bottom-20">{{ $t('联系电话') }}</div>
						<input type="text" :placeholder="$t('请输入')" v-model="phone">
					</div>
					<div class="item">
						<div class="_pc-font-20 _pc-mar-bottom-20">{{ $t('state') }}</div>
						<div class="item" style="position: relative;cursor:pointer;">
							<div class="input-box _flex-row-bet-mid _pc-pad-right-15 _w-max" @click="getCountrys(-1)" style="background-color: #f7f7f7;padding:20px 20px;border-radius: 7px;">
								<div class="line-1 _pc-font-20" :style="country_name ? '' : 'color:#999'">{{country_name!==''?country_name:$t('请选择')}}</div>
								<div class="iconfont2 icon-xiala"></div>
							</div>
							<div class="select-box" v-if="country_show" style="overflow:scroll;min-height: 50px;max-height: 300px;overflow-x: hidden;">
								<div class="li line-1" v-for="(item,index) in country_list" :key="index" :title="$t('收货地址')+'\n   '+item" @click="getCountrys(index,item)">{{item.name}}</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="_pc-font-20 _pc-mar-bottom-20">{{ $t('city') }}</div>
						<div class="item" style="position: relative;cursor:pointer;">
							<div class="input-box _flex-row-bet-mid _pc-pad-right-15 _w-max" @click="getCitys(-1)" style="background-color: #f7f7f7;padding: 20px 20px;border-radius: 7px;">
								<div class="line-1 _pc-font-20" :style="city_name ? '' : 'color:#999'">{{city_name!==''?city_name:$t('请选择')}}</div>
								<div class="iconfont2 icon-xiala"></div>
							</div>
							<div class="select-box" v-if="city_show" style="overflow:scroll;min-height: 50px;max-height: 300px;overflow-x: hidden;">
								<div class="li line-1" v-for="(item,index) in city_list" :key="index" :title="$t('收货地址')+'\n'+item" @click="getCitys(index,item)">
									{{ item.name }}
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div class="_w-max address-info-box">
					<div class="_flex-row-mid _pc-mar-bottom-15 _pc-font-20" style="color: #333;">{{ $t('地址') }}</div>
					<textarea class="address-info" id="" cols="30" rows="10" :placeholder="$t('请输入')" v-model="address"></textarea>
				</div>		
			</div>
			
			<div class="btn" @click="submit">{{ $t('更改') }}</div>
		</div>
	</div>
</template>

<script>
	import { getUserInfo, getCountry, cityList, changeInfo, bannerList } from '@/api/jiyun.js'
	export default {
		name: 'editUserInfo',
		data() {
			return {
				title: this.$t('修改地址'),
				model1: '',
				// 国家
				country_list:[],
				country_index:'',
				country_show:false,
				country_name: '',
				area_id: 0,
				// 城市
				city_list:[],
				city_index:'',
				city_show:false,
				city_name: '',
				city_id: 0,
				nickname: '',
				phone: '',
				address: '',
				userInfo: {},
				lang: 'zh_cn',
				agent_info: {},
			}
		},
		created(){
			this.lang = localStorage.getItem('think-lang') || 'zh_cn';
			this.getCountry();
		},
		mounted(){
			this.getUserInfo();
			this.getAgentInfo();
		},
		methods: {
			// 代理banner
			getAgentInfo(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
			// 提交
			submit(){
				let param = {
					nickname: this.nickname,
					phone: this.phone,
					area_id: this.area_id,
					area_name: this.country_name,
					province_id: this.city_id,
					province_name: this.city_name,
					addres: this.address,
					domain_url: window.location.origin
				}
				changeInfo(param).then((res)=>{
					this.$Message.success(res.msg);
					this.$emit('IsShow',false);
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 获取用户信息
			getUserInfo(){
				getUserInfo().then((res)=>{
					this.userInfo = res.data;
					this.nickname = this.userInfo.nickname;
					this.phone = this.userInfo.phone;
					this.address = this.userInfo.addres;
					this.area_id = this.userInfo.area_id;
					this.country_name = this.userInfo.area_name;
					if(this.area_id){
						this.getCity()
					}
					this.city_id = this.userInfo.province_id;
					this.city_name = this.userInfo.province_name;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			close() {
				this.$emit('IsShow',false);
			},
			// 获取城市列表
			getCity(){
				let that = this;
				let param = {
					pid: that.area_id,
					domain_url: window.location.origin
				}
				cityList(param).then((res)=>{
					that.city_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 选择城市
			getCitys(index,city){
				if(!this.area_id) return this.$Message.error(this.$t('请先选择国家或地区'));
				if(index==-1){
					if(!this.agent_info.is_main) return;
					this.city_show = !this.city_show;
				} else {
					this.city_show = !this.city_show;
					this.city_index = index;
					this.city_id = city.id;
					this.city_name = city.name;
				}
			},
			// 获取国家列表
			getCountry(){
				getCountry().then((res)=>{
					this.country_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 选择国家
			getCountrys(index,country) {
				if(index==-1){
					if(!this.agent_info.is_main) return;
					this.country_show = !this.country_show;
				} else {
					this.country_show = !this.country_show;
					this.country_index = index;
					this.area_id = country.id;
					this.country_name = country.name;
					this.city_id = 0;
					this.city_name = '';
					this.getCity();
				}
			},
		}
	}
</script>

<style scoped>
	input {
		width: 100%;
		padding: 20px;
		border-radius: 7px;
		background-color: #f7f7f7;
		outline: none;
		font-size: 18px;
		/* margin-bottom: 35px; */
	}
	input::placeholder {
		color: #999999;
	}
	.index {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #333333;
	}
	.main-box {
		padding: 55px;
		border-radius: 16px;
		background-color: #ffffff;
		/* width: 1075px; */
		width: 578px;
	}
	.main-box .title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.main-box .title .close {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background: #e7e7e7;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #999;
		font-size: 16px;
	}
	.main-box .edit-info .address-info-box .address-info {
		width: 100%;
		height: 105px;
		border-radius: 7px;
		background-color: #f7f7f7;
		padding: 20px 20px;
		outline: none;
		border: none;
		font-size: 20px;
	}
	.main-box .edit-info .li-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.main-box .edit-info .li-box .item {
		width: 470px;
		margin-bottom: 27px;
	}
	.main-box .btn {
		width: 424px;
		height: 62px;
		border-radius: 12px;
		margin: 0 auto;
		/* margin-top: 40px; */
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		font-size: 20px;
		background-color: #4284DC;
	}
	.main-box .btn:hover {
		cursor: pointer;
		opacity: 0.4;
	}
	.select-box {
		position: absolute;
		width: 100%;
		min-height: 70px;
		z-index: 99;
		/* top: 110px; */
		left: 0;	
		background-color: #ffffff;
	}
	.select-box .li {
		width: 100%;
		height: 70px;
		padding: 0px 30px;
		line-height: 70px;
		font-size: 18px;
		/* display: flex; */
		/* align-items: center; */
		
		/* justify-content: center; */
	}
	.line-1 {
		
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.select-box .li:hover {
		border: 1px solid #D6D6D6;
		
	}
	.icon-xiala {
		font-size: 20px;
		color: #999999;
	}
</style>