<template>
	<div class="edit-address-container" v-if="IsShow">
		<div class="mark" @click="close(0)"></div>
		<div class="address-from-container" @click.stop>
			<div class="address-from-outbox">
				<div class="from-header">
					<div class="title">{{(id != 0)?$t('修改地址'): $t('新建地址')}}</div>
					<div class="btn" style="display: flex;align-items: center;" @click="close(0)">
						<img style="width: 0.9375rem;" src="@/assets/image/hangda/fanhui2.png" alt="">
						<span style="color: #4284DC;margin-left: 0.375rem;">返回</span>
					</div>
				</div>
				<div class="from-body">
					<div class="col">
						<div class="name">{{$t('收件人')}}</div>
						<div class="input-box">
							<input type="text" v-model="linkman" :placeholder="$t('请输入')" />
						</div>
					</div>
					<div class="col">
						<div class="name">{{$t('联系电话')}}</div>
						<div class="input-box">
							<input type="text" v-model="mobile" :placeholder="$t('请输入')" />
						</div>
					</div>
					<div class="col" style="cursor: pointer;">
						<div class="name">{{$t('国家/地区')}}</div>
						<div class="input-box" @click="openCountry">
							<div style="flex: 1;">
								<span :class="area ? '' : '_grey-99'">{{area || $t('请选择')}}</span>
							</div>
							<div @click="openCountry">
								<div class="iconfont2 icon-xiala" style="color: #999;"></div>
							</div>
							<div class="select-address-container" v-if="open_country">
								<div class="select-item" v-for="(item,index) in country_list" :key="index" @click.stop="getCountrys(item)">{{item.address_name}}</div>
							</div>
						</div>
					</div>
					<div class="col" style="cursor: pointer;">
						<div class="name">{{$t('省/州')}}</div>
						<div class="input-box" @click="openProvince">
							<div style="flex: 1;">
								<span :class="province ? '' : '_grey-99'">{{province||$t('请选择')}}</span>
							</div>
							<div @click="openProvince">
								<div class="iconfont2 icon-xiala" style="color: #999;"></div>
							</div>
							<div class="select-address-container" v-if="open_province">
								<div class="select-item" v-for="(item,index) in province_list" :key="index"  @click.stop="getProvinces(item)">{{item.label}}</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="name">{{$t('城市')}}</div>
						<div class="input-box">
							<input type="text" v-model="city" :placeholder="$t('请输入')" />
						</div>
					</div>
					<div class="col">
						<div class="name">{{$t('邮编')}}</div>
						<div class="input-box">
							<input type="text" v-model="zip_code" :placeholder="$t('请输入')" />
						</div>
					</div>
					<div class="col grid-column-max-span">
						<div class="name">{{$t('详细地址')}}</div>
						<div class="input-box">
							<textarea style="width: 100%;min-height: 5rem;" v-model="address" :placeholder="$t('请输入')"></textarea>
						</div>
					</div>
				</div>
				<div class="from-sub-btn">
					<div></div>
					<div class="btn" @click="submit()">{{id ? $t('修改地址') : $t('新建地址')}}</div>
					<div></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { editReceiveAddress, getCountry, getProvinceOption } from '@/api/jiyun.js'
	export default {
		props: {
			"IsShow":{
				type: Boolean,
				default:false
			},
		},
		data() {
			return {
				open_country: false,
				open_province: false,
				id: 0,
				linkman: '',
				mobile: '',
				area_id: 0,
				area: '',
				province: '',
				city: '',
				address:'',
				zip_code: '',
				country_list: [],
				province_list: [],
			}
		},
		mounted() {
			this.getCountry();
		},
		methods: {
			// 省州下拉
			openProvince(){
				if(!this.area_id) return this.$Message.info(this.$t('请先选择国家或地区'));
				this.open_province = !this.open_province;
				this.open_country = false;
			},
			// 打开国家下拉
			openCountry(){
				this.open_country = !this.open_country;
				this.open_province = false;
			},
			close(type) {
				this.$emit('close',false,type)
			},
			// 获取国家列表
			getCountry(){
				getCountry().then((res)=>{
					this.country_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 获取城市列表
			getProvince(){
				let that = this;
				let param = {
					pid: that.area_id
				}
				getProvinceOption(param).then((res)=>{
					that.province_list = res.data;
					if(that.province_list.length <= 0){
						that.$Message.info(that.$t('请重新选择国家或地区'));
						that.area_id = 0;
						that.area = '';
						that.province = '';
					}
				}).catch((err)=>{
					that.$Message.error(err.msg)
				})
			},
			// 选择国家
			getCountrys(country) {
				this.open_country = false;
				this.area_id = country.id;
				this.area = country.address_name;
				this.province = '';
				this.city = '';
				this.getProvince();
			},
			// 选择城市
			getProvinces(province){
				this.open_province = false;
				this.province = province.label;
			},
			submit() {
				let that = this;
				let param = {
					id: this.id,
					linkman: this.linkman,
					mobile: this.mobile,
					area_id: this.area_id,
					area: this.area,
					province: this.province,
					city: this.city,
					address: this.address,
					zip_code: this.zip_code,
				}
				//修改信息
				editReceiveAddress(param).then(res=>{
					this.$Message.success(this.$t('提交成功'));
					setTimeout(()=>{
						that.close(1);
					},800)
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
		}
	}
</script>

<style scoped>
	input {
		outline: none;
		background: #F7F7F7;
	}
	textarea {
		outline: none;
		background: #F7F7F7;
	}
	.grid-column-max-span{
		grid-column: 1 / -1;
	}
	.edit-address-container {
		position: fixed;
		background: rgba(0, 0, 0, 0.3);
		z-index: 999;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.edit-address-container .mark {
		position: absolute;
		inset: 0;
	}
	.edit-address-container .address-from-container {
		position: relative;
		z-index: 2;
		/* width: 80%; */
		border-radius: 0.625rem;
		background-color: #ffffff;
		border: 1px solid #fff;
	}
	.edit-address-container .address-from-container .address-from-outbox{
		width: 100%;
		color: #333333;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.from-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0rem 0.9375rem;
		height: 6.25rem;
		border-bottom: 1px solid #d8d8d8;
	}
	.from-header .title {
		font-weight: bold;
		font-size: 1.75rem;
	}
	.from-header .btn {
		cursor: pointer;
		padding: 0.375rem 0.625rem;
		border-radius: 0.375rem;
		border: 1px solid #4284DC;
	}
	.from-body {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 3.125rem;
		grid-row-gap: 1rem;
		padding: 1.625rem 0.9375rem;
		font-size: 0.9375rem;
	}
	.from-body .col{
		display: flex;
		flex-direction: column;
		row-gap: 0.9375rem;
		width: 100%;
	}
	.from-body .col .input-box {
		width: 100%;
		border-radius: 0.375rem;
		background: #F7F7F7;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.9375rem 0.9375rem;
		position: relative;
	}
	.from-sub-btn {
		padding: 1.25rem 0;
		width: 100%;
		display: flex;
		justify-content: center;
		display: grid;
		grid-template-columns: 30% 1fr 30%;
	}
	.from-sub-btn .btn {
		background: #4284DC;
		border-radius: 0.375rem;
		color: #fff;
		display: flex;
		align-items: center;
		padding: 0.9375rem 0rem;
		justify-content: center;
		cursor: pointer;
	}
	
	/* 下拉选择 */
	.select-address-container {
		position: absolute;
		left: 0;
		top: 110%;
		background-color: #fff;
		border-radius: 0.5rem;
		width: 100%;
		min-height:  2.8125rem;
		max-height: calc(2.8125rem * 6);
		border: 1px solid #D6D6D6;
		z-index: 2;
		box-shadow: -1px 7px 9px 4px rgba(0, 0, 0, 0.3);
		overflow: hidden;
	}
	.select-address-container .select-item {
		width: 100%;
		padding: 0rem 0.9375rem;
		display: flex;
		align-items: center;
		height: 2.8125rem;
		border-bottom: 1px solid #f4f4f4;
	}
	/* .mb-main-box .mb-main-box-li .mb-li .input-box .select-address-container .select-item:hover {
		background-color: rgba(66, 132, 220, 0.3);
		color: #999;
	} */
	.select-address-container .select-item:last-child {border-bottom: none;}
</style>