<template>
	<div class="index">
		<div class="main-box">
			<div class="title">
				<div class="_pc-font-32 _b">{{title}}</div>
				<div class="iconfont2 icon-cha1 close" @click="close"></div>
			</div>
			<!-- 修改密码 -->
			<div class="_w-max">
				<div class="_flex-row-mid _pc-mar-bottom-15">
					<img class="_pc-mar-right-10 _pc-w-20" src="~@/assets/image/hangda/logo/password.png" alt="" />{{ $t('旧密码') }}
				</div>
				<input type="password" :placeholder="$t('请输入')" v-model="used_pwd">
				<div class="_flex-row-mid _pc-mar-bottom-15">
					<img class="_pc-mar-right-10 _pc-w-20" src="~@/assets/image/hangda/logo/password.png" alt="" />{{ $t('新密码') }}
				</div>
				<input type="password" :placeholder="$t('请输入')" v-model="new_pwd">
				<div class="_flex-row-mid _pc-mar-bottom-15">
					<img class="_pc-mar-right-10 _pc-w-20" src="~@/assets/image/hangda/logo/password.png" alt="" />{{ $t('确认新密码') }}
				</div>
				<input type="password" :placeholder="$t('请输入')" v-model="affirm_pwd">
			</div>
			
			<div class="btn" @click="submit">{{ $t('更改') }}</div>
		</div>
	</div>
</template>

<script>
	import { changePwd } from '@/api/jiyun'
	export default {
		name: 'editPassword',
		data() {
			return {
				title: this.$t('更改密码'),
				used_pwd: '',
				new_pwd: '',
				affirm_pwd: '',
			}
		},
		methods: {
			// 提交
			submit(){
				let param = {
					used_pwd: this.used_pwd,
					new_pwd: this.new_pwd,
					affirm_pwd: this.affirm_pwd,
					domain_url: window.location.origin
				}
				changePwd(param).then((res)=>{
					this.$Message.success(res.msg);
					this.$emit('IsShow',false);
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
			close() {
				this.$emit('IsShow',false);
			}
		}
	}
</script>

<style scoped>
	input {
		width: 100%;
		padding: 20px;
		border-radius: 7px;
		background-color: #f7f7f7;
		outline: none;
		font-size: 18px;
		margin-bottom: 35px;
	}
	input::placeholder {
		color: #999999;
	}
	.index {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #333333;
	}
	.main-box {
		padding: 55px;
		border-radius: 16px;
		background-color: #ffffff;
		width: 580px;
	}
	.main-box .title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.main-box .title .close {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background: #e7e7e7;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #999;
		font-size: 16px;
	}
	.main-box .btn {
		width: 100%;
		height: 62px;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		font-size: 20px;
		background-color: #4284DC;
	}
	.main-box .btn:hover {
		cursor: pointer;
		opacity: 0.4;
	}
</style>